class layoutBase
{
    public static documentReady(): void
    {
        layoutBase.setupLobibox();
        layoutBase.setupDatePicker();
    }

    public static documentAjaxComplete(): void
    {
        layoutBase.setupDatePicker();
    }

    public static setupDatePicker(): void
    {
        //if browser does not support date control (like IE) or is Edge (which has a crummy date control), then change the date controls to our own datepicker.
        if (!Modernizr.inputtypes.date || window.activeBrowser.browserUtility.microsoftEdge)
        {
            //Get date inputs to change them to date pickers. Don't include inputs for which we've already added a date picker (indicated by class convertedDateControl)
            var $dateInputs = $("input[type='date']:not(.convertedDateControl)");

            if ($dateInputs.length)
            {
                $dateInputs.each(layoutBase.changeToDatepicker);
            }
        }
    }

    private static changeToDatepicker(index: number, element: HTMLElement): void
    {
        var $element = $(element);

        if (!$element.hasClass('convertedDateControl')) //check if already converted, sometimes the conversion process runs more than once async
        {
            $element.prop('type', 'text'); //This is ignored in IE.

            //Because setting the type to text doesn't work in IE, we add this class to indicate that this element has changed.
            $element.addClass("convertedDateControl");

            $element.attr("maxlength", 10);

            const newFormat: string = "MM/DD/YYYY";

            layoutBase.changeDateFormat($element, newFormat);

            $element.data("format", newFormat);

            $element.on("keyup", layoutBase.dateKeyUp);

            var inputName = $element.prop("name");

            var $span = $('<span data-inputname="' + inputName + '" class="input-group-addon open-datepicker"><i class="fa fa-calendar" aria-hidden="true"></i></span>');

            $element.after($span);

            $element.datepicker({ showOnFocus: false, autoclose: true, });

            $span.on("click", layoutBase.dateSpanClick);
        }
    }

    public static dateSpanClick(): void
    {
        var inputName = $(this).data("inputname");

        var $dateInput = $("input[name='" + inputName + "']");

        $dateInput.datepicker('show');
    }

    public static dateKeyUp(e): void
    {
        const backspace = 8;

        var value = $(this).val();
        var length = value.length;

        if (length > 0)
        {
            var updateValue = false;

            var lastChar = value[length - 1];

            var lastIsSlash = lastChar === "/";

            var keyIsBackSpace = e.keyCode === backspace;

            var isSlashPosition = (length === 2 || length === 5);

            if (keyIsBackSpace)
            {
                //goal : remove numbers plus slash (i.e. 08/ becomes 0)
                if (lastIsSlash)
                {
                    value = value.substring(0, length - 1);
                    updateValue = true;
                }
            }
            else
            {
                if (lastIsSlash)
                {
                    //goal: eliminate double slash
                    var secondToLastChar = '';

                    if (length > 1)
                    {
                        secondToLastChar = value[length - 2];
                    }

                    if (secondToLastChar === '/')
                    {
                        value = value.substring(0, length - 1);
                        updateValue = true;
                    }
                }

                //goal : add 0 to month (i.e. 8/ becomes 08/)
                if (isSlashPosition && lastIsSlash)
                {
                    var position = length - 2;
                    value = [value.slice(0, position), "0", value.slice(position)].join("");
                    updateValue = true;
                }

                //goal : add / to month (i.e. 08 becomes 08/)
                if (isSlashPosition && !lastIsSlash)
                {
                    value += "/";
                    updateValue = true;
                }
            }

            if (updateValue)
            {
                $(this).val(value);
            }
        }
    }

    private static changeDateFormat($element: JQuery, newFormat: string): void
    {
        var unformattedValue = $element.val();

        var format = $element.data("format");

        var valueisNewFormat: boolean = false;

        //when the control value is loaded by modelstate it may already be in the new format
        if (unformattedValue.indexOf('/') > -1 && newFormat.indexOf('/') > -1)
        {
            valueisNewFormat = true;
        }

        if (!valueisNewFormat)
        {
            var date: moment.Moment = moment(unformattedValue, format);

            if (date.isValid())
            {
                var formatedValue = date.format(newFormat);

                $element.val(formatedValue);
            }
        }
    }

    private static setupLobibox(): void
    {
        Lobibox.notify.DEFAULTS = $.extend({}, Lobibox.notify.DEFAULTS, {
            //override any options from default options
            soundPath: virtualDir + 'Content/lobibox/dist/sounds/',
            sound: false,
        });
        Lobibox.alert.DEFAULTS = $.extend({}, Lobibox.alert.DEFAULTS, {
            //override any options from default options
            soundPath: virtualDir + 'Content/lobibox/dist/sounds/',
            sound: false,
        });
    }
}

class functions
{
    public static isNullOrWhitespace(value: string): boolean
    {
        var result = false;

        var whiteSpace: RegExp = /\s/g;

        if (typeof value === 'undefined' || value == null)
        {
            result = true;
        }
        else if (value.replace(whiteSpace, '').length < 1)
        {
            result = true;
        }

        return result;
    }
}

$(document).ready(layoutBase.documentReady);
$(document).ajaxComplete(layoutBase.documentAjaxComplete);